// Copyright © 2021 The Things Industries B.V.

.info
  margin-top: 0

.h-rule
  margin-bottom: $ls.s

.logo
  display: block
  max-width: 12rem
  margin-bottom: $ls.xs

.generate-api-key-button
  margin-bottom: $cs.xs

.further-resources
  margin-bottom: $cs.xs
